import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  denormalizeFeatureFlag,
  normalizeFeatureFlag,
} from '@/screens/admin/UserManagement/featureFlags/utils.ts'
import { FeatureFlag, FeatureFlagFromAPI } from '@/types/featureFlag.ts'
import { Queue } from '@/types/queue.ts'

import { useFetchFromAPI } from '../data/useFetchFromAPI.ts'

const featureFlagQueryOptions = queryOptions({
  queryKey: ['featureFlag'],
})

export const useFeatureFlags = (): {
  featureFlags: FeatureFlag[],
  isLoading: boolean
} => {
  const fetchAPI = useFetchFromAPI()

  const { data: featureFlags, isLoading } = useQuery<Array<FeatureFlag>>({
    queryFn: async () => {
      return fetchAPI<Array<FeatureFlagFromAPI>, unknown>(
        `admin/feature-flags`,
      ).then((data) => (data ? data.map(normalizeFeatureFlag) : []))
    },
    queryKey: featureFlagQueryOptions.queryKey,
  })

  return {
    featureFlags: featureFlags ?? [],
    isLoading,
  }
}

type FeatureFlagMutationProps = {
  onSuccess?: () => void
}

export const useCreateFeatureFlag = ({
  onSuccess,
}: FeatureFlagMutationProps) => {
  const fetchAPI = useFetchFromAPI('POST')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    FeatureFlag,
    unknown,
    { featureFlag: FeatureFlag }
  >({
    mutationFn: async ({ featureFlag }) =>
      fetchAPI('admin/feature-flags', denormalizeFeatureFlag(featureFlag)),
    onSuccess: (_, data) => {
      queryClient.setQueryData(
        featureFlagQueryOptions.queryKey,
        (oldData: Queue[]) => [...oldData, data],
      )
      onSuccess?.()
    },
  })

  return {
    createFeatureFlag: mutate,
    featureFlag: data,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}

export const useUpdateFeatureFlag = ({
  onSuccess,
}: FeatureFlagMutationProps) => {
  const fetchAPI = useFetchFromAPI('PUT')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    FeatureFlag,
    unknown,
    { featureFlag: FeatureFlag; key: string }
  >({
    mutationFn: async ({ featureFlag, key }) =>
      fetchAPI(
        `admin/feature-flags/${key}`,
        denormalizeFeatureFlag(featureFlag),
      ),
    onError: () => {
      // Rollback on error
      queryClient.invalidateQueries({
        queryKey: featureFlagQueryOptions.queryKey,
      })
    },
    onMutate: async ({ featureFlag, key }) => {
      queryClient.setQueryData(
        featureFlagQueryOptions.queryKey,
        (oldData: FeatureFlag[]) => {
          return oldData.map((item) =>
            item.key === key ? { ...item, ...featureFlag } : item,
          )
        },
      )
    },
    onSuccess: () => {
      onSuccess?.()
    },
  })

  return {
    featureFlag: data,
    isError,
    isLoading: isPending,
    isSuccess,
    updateFeatureFlag: mutate,
  }
}

export const useDeleteFeatureFlag = ({
  onSuccess,
}: FeatureFlagMutationProps) => {
  const fetchAPI = useFetchFromAPI('DELETE')
  const queryClient = useQueryClient()

  const { data, isError, isPending, isSuccess, mutate } = useMutation<
    FeatureFlag,
    unknown,
    { key: string }
  >({
    mutationFn: async ({ key }) => fetchAPI(`admin/feature-flags/${key}`),
    onSuccess: (_, deletedFeatureFlag) => {
      queryClient.setQueryData(
        featureFlagQueryOptions.queryKey,
        (oldData: FeatureFlag[]) => {
          if (oldData) {
            return oldData.filter(
              (featureFlag) => featureFlag.key !== deletedFeatureFlag.key,
            )
          }
          return []
        },
      )
      onSuccess?.()
    },
  })

  return {
    deleteFeatureFlag: mutate,
    featureFlag: data,
    isError,
    isLoading: isPending,
    isSuccess,
  }
}
