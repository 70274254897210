import dayjs from 'dayjs'
import styled from 'styled-components'

import { BodyM, BodyS } from '@/components/common/Text.tsx'

const Span = styled(BodyM).attrs({
  as: 'span',
})``

export const DateSpan = ({
  className,
  date,
}: {
  className?: string
  date: string
}) => {
  return <Span className={className}>{dayjs(date).format('DD/MM/YYYY')}</Span>
}

type DateTimeProps = {
  end?: string
  start: string
}

export const DateTime = ({ end, start }: DateTimeProps) => {
  const date = dayjs(start).format('DD/MM/YYYY')
  const startTime = start && dayjs(start).format('HH:mm')
  const endTime = end && dayjs(end).format('HH:mm')

  const timeRange = [startTime, endTime].filter(Boolean).join('-')

  return <BodyS fontWeight="bold">{`${date} | ${timeRange}`}</BodyS>
}
