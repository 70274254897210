import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledActionButton } from '@/components/common/Buttons.tsx'
import { Header2 } from '@/components/common/Text.tsx'
import { useCreateFeatureFlag } from '@/hooks/admin/useFeatureFlags.ts'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { FeatureFlagsTable } from '@/screens/admin/UserManagement/featureFlags/FeatureFlagsTable.tsx'
import { useFeatureFlagFormFields } from '@/screens/admin/UserManagement/featureFlags/useFeatureFlagFormFields.ts'

import * as S from '../UserManagement.styled.ts'

export const FeatureFlags = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.featureFlags',
  })
  const { featureFlagFormFields } = useFeatureFlagFormFields()
  const { createFeatureFlag, isError: isErrorCreatingFeatureFlag } =
    useCreateFeatureFlag({
      onSuccess: () => {
        setNewFeatureFlagModal(false)
      },
    })

  const [newFeatureFlagModal, setNewFeatureFlagModal] = useState<boolean>(false)
  return (
    <div>
      <S.HeaderBar>
        <Header2>{t('title')}</Header2>
        <S.StyledActionButtons>
          <StyledActionButton
            onClick={() => {
              setNewFeatureFlagModal(true)
            }}
            variant="Primary"
          >
            {t('actions.add')}
          </StyledActionButton>
        </S.StyledActionButtons>
      </S.HeaderBar>
      <FeatureFlagsTable />
      {newFeatureFlagModal && (
        <EditModal
          closeModal={() => setNewFeatureFlagModal(false)}
          errorMessage={t('createModal.error')}
          formFields={featureFlagFormFields}
          isError={isErrorCreatingFeatureFlag}
          onSave={(featureFlag) => {
            createFeatureFlag({
              featureFlag,
            })
          }}
          title={t('createModal.title')}
        />
      )}
    </div>
  )
}
