import { FeatureFlag, FeatureFlagFromAPI } from '@/types/featureFlag.ts'

export const normalizeFeatureFlag = (flag: FeatureFlagFromAPI): FeatureFlag => {
  return {
    ...flag,
    description: flag.description ?? '',
    value: flag.value ? flag.value.join(',') : '',
  }
}

export const denormalizeFeatureFlag = (
  flag: FeatureFlag,
): FeatureFlagFromAPI => {
  return {
    ...flag,
    description: flag.description ?? '',
    enabled: flag.enabled ?? false,
    value: flag.value
      ? flag.value
          .replaceAll(new RegExp(/["\[\]]/g), '')
          .split(',')
          .map((str) => str.trim())
      : [],
  }
}
